<template>
    <div class="MemberCenter">
        <el-tabs v-model="activeName" class="custom-tabs" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="已完成订单" name="first">
                <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
                    element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
                    <el-table-column label="序号" type="index" width="80" align="center">
                    </el-table-column>
                    <el-table-column prop="ordername_name" label="订单名" align="center"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="ordernum_name" label="订单号" align="center"
                        show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="paymentstate_name" label="交易状态" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ getPaymentStateValue(scope.row.paymentstate_name) }}
                        </template>
</el-table-column> -->
                    <el-table-column prop="creatortime" label="创建时间" align="center"
                        show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="purchasemethod_name" label="购买方式" align="center"
                        show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="paymentamount_name" label="实付金额" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            ￥{{ scope.row.paymentamount_name }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="licensestatus" label="许可证状态" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ getLicenseStatus(scope.row.licensestatus) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="相关下载" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitMap')"
                                @click="toDownload('map')">SummitMap</el-button>
                            <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitMap')"
                                @click="toDownload('environment')">环境包</el-button>
                            <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitMapHUBEI')"
                                @click="toDownload('HUBEI')">SummitMapHUBEI</el-button>
                            <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitSolid')"
                                @click="toDownload('solid')">SummitSolid</el-button>
                            <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitLab')"
                                @click="toDownload('lab')">SummitLab</el-button>
                            <el-button type="text" v-if="getSoftType(scope.row.ordername_name, 'SummitSolidSGJ')"
                                @click="toDownload('solidSGJ')">SummitSolidSGJ</el-button> -->
                            <el-button type="text" @click="toDownloadAll(scope.row.ordername_name)">下载</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="goDetail(scope.row.id)">详情</el-button>
                            <el-button type="text" v-if="scope.row.licensestatus == 0"
                                @click="goLicense()">许可证申请</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
                    @current-change="handleCurrentChange" @pagination="getInit" />
            </el-tab-pane>
            <el-tab-pane label="待支付" name="second">
                <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
                    element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
                    <el-table-column label="序号" type="index" width="80" align="center">
                    </el-table-column>
                    <el-table-column prop="ordername_name" label="订单名" align="center"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="ordernum_name" label="订单号" align="center"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="creatortime" label="创建时间" align="center"
                        show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="paymentstate_name" label="交易状态" align="center" show-overflow-tooltip>

                        <template slot-scope="scope">
                            {{ getPaymentStateValue(scope.row.paymentstate_name) }}
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="purchasemethod_name" label="购买方式" align="center"
                        show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="paymentamount_name" label="待付款" align="center"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" @click="goDetail(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination :total="total1" :page.sync="listQuery1.currentPage" :limit.sync="listQuery1.pageSize"
                    @pagination="getInit" />
            </el-tab-pane>
            <!-- <el-tab-pane label="待续费" name="third">待续费</el-tab-pane> -->
            <!-- <el-tab-pane label="物流状态" name="four">物流状态</el-tab-pane> -->
            <!-- <el-tab-pane label="许可申请状态" name="five">许可申请状态</el-tab-pane> -->
        </el-tabs>
        <Search :position="true" @search="search" :searchContent="searchContent"></Search>
        <OrderDetail ref="OrderDetail" v-if="detailVisible" @refresh="detailVisible = false"></OrderDetail>
        <el-dialog title="下载" :close-on-click-modal="false" lock-scroll width="1000px" :visible.sync="downloadVisible">
            <div class="downloadList">
                <el-button type="primary" round @click="downloadSoft()">安装包</el-button>
                <!-- <el-button type="primary" round>许可证</el-button> -->
                <el-button type="primary" round v-if="getSoftType('SummitMap')"
                    @click="downloadSoft('SummitMapEnvironment')">环境包</el-button>
                <el-button type="primary" round v-if="getSoftType('SummitLab')"
                    @click="downloadSoft('SummitLabData')">教学数据</el-button>
                <!-- <el-button type="primary" round>操作视频</el-button> -->
                <!-- <el-button type="primary" round>使用手册</el-button> -->
                <!-- <el-button type="primary" round>样例数据</el-button> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Search from './Search.vue';
import OrderDetail from './OrderDetail.vue';
import request from '@/utils/request';
import { mapState, mapMutations } from "vuex";
import { getSoftDownloadList } from "@/assets/API/pay";
export default {
    components: {
        Search, OrderDetail
    },
    data() {
        return {
            activeName: 'first',
            tableData: [],
            loading: false,
            total: 0,
            total1: 0,
            query: {
                userid: '',// 用户id
                paymentstate: "1", //（0-未支付，1-已支付，2-支付失败）
                purchasemethod: 'online',
                menuId: "491144407886626501"
            },
            listQuery: {
                dataType: 0,
                superQueryJson: '',
                currentPage: 1,
                pageSize: 10,
                sort: "desc",
                sidx: "",
            },
            listQuery1: {
                dataType: 0,
                superQueryJson: '',
                currentPage: 1,
                pageSize: 10,
                sort: "desc",
                sidx: "",
            },
            paymentStateList: [
                {
                    value: "0",
                    label: '未支付'
                },
                {
                    value: "1",
                    label: '已支付'
                },
                {
                    value: "2",
                    label: '支付失败'
                },
            ],
            orderCount: "",
            searchContent: "输入订单编号",
            detailVisible: false,
            downloadList: [],
            downloadVisible: false,
            choosedSoft: ''
        };
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    mounted() {
        this.query.userid = this.userInfo.id;
        this.getInit();
        let query = {
            dataType: 1,
            sort: "desc",
            sidx: "",
            type: 1
        };
        getSoftDownloadList(query).then(res => {
            this.downloadList = res.data.list;
        });
    },
    watch: {

    },
    methods: {
        // 数据初始化
        getInit() {
            this.loading = true;
            if (this.query.userid) {
                let _query = {
                    ...this.listQuery,
                    ...this.query
                };
                request({
                    url: `/api/business/C_customers/getOrderListWithStatus`,
                    method: 'post',
                    data: _query
                }).then(res => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.tableData = res.data.list;
                        this.total = res.data.pagination.total;
                        if (this.query.paymentstate == "1") {
                            this.orderCount = res.data.list.length;
                            // this.orderCountLabel = "已完成订单(" + this.total + ")";
                        }
                    }
                });
            }
        },
        getLicenseStatus(val) {
            if (val == 0 || val == 2) {
                return '未创建';
            } else if (val == 1) {
                return '已创建';
            }
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.listQuery.currentPage = val;
            this.getInit();
        },
        handleCurrentChange1(val) {
            console.log(`当前页: ${val}`);
            this.listQuery.currentPage = val;
            this.getInit();
        },
        getPaymentStateValue(val) {
            let res = this.paymentStateList.find(function (e) {
                return e.value == val;
            });
            return res.label;
        },
        goDetail(id) {
            this.detailVisible = true;
            this.$nextTick(() => {
                this.$refs.OrderDetail.init(id);
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
            console.log("tab.name", tab.name);
            this.tableData = [];
            if (tab.name == "first") {
                this.query.paymentstate = "1";
            } else if (tab.name == "second") {
                this.query.paymentstate = "0";
            }
            this.listQuery.currentPage = 1;
            this.listQuery.pageSize = 10;
            this.getInit();
        },
        search(val) {
            console.log("val", val);
            this.query.ordernum = val;
            this.getInit();
        },
        toDownload(value) {
            // 软件下载链接
            let url = "";
            let typeName = '';
            // if (value == 'map') {
            //     // url = "https://pan.baidu.com/s/1r9GeqkWtIObwvyiZHVIvXw" + "&pwd=" + "l7yv";
            //     typeName = "SummitMap";
            // } else if (value == 'HUBEI') {
            //     // url = "https://pan.baidu.com/s/1t401sb1rpcfLeeAzs5g-Ng" + "&pwd=" + "w154";
            //     typeName = "SummitMapHUBEI";

            // } else if (value == 'solid') {
            //     // url = "https://pan.baidu.com/s/1RvyukDTDdb5wiXtKZRYBOA" + "&pwd=" + "qk1d";
            //     typeName = "SummitSolid";

            // } else if (value == 'lab') {
            //     // url = "https://pan.baidu.com/s/17oYO_QunJ_mibuQayjmi1w" + "&pwd=" + "ppg3";
            //     typeName = "SummitLab";

            // } else if (value == 'environment') {
            //     // url = "https://pan.baidu.com/s/1ZLP7bBZssqLK9Db8sH-gTA?pwd=wqyq";
            //     typeName = "SummitMapEnvironment";
            // } else if (value == 'solidSGJ') {
            //     // url = "https://pan.baidu.com/s/1WpsKKZQWCxLIfCE6SQ5fwg" + "&pwd=" + "vleo";
            //     typeName = "SummitSolidSGJ";
            // }
            // let res = this.downloadList.filter(n => n.descrh == typeName);
            let res = this.downloadList.filter(n => n.descrh == value);
            if (res.length == 1) {
                url = res[0].f + "&pwd=" + res[0].m;
                window.open(url, '_blank');
            }
        },
        goLicense() {
            this.$emit("toApply", true);
        },
        getSoftType(type) {
            var result = false;
            if (this.choosedSoft && this.choosedSoft == type) {
                // var res = this.choosedSoft.slice(0, -2);
                // var resList = res.split('-');
                // resList.forEach(e => {
                //     if (e == type) {
                //         result = true;
                //     }
                // });
                result = true;

            }
            return result;
        },
        toDownloadAll(data) {
            var res = data.slice(0, -2);
            console.log(res, 'sssss');
            this.choosedSoft = res;
            this.downloadVisible = true;
        },
        downloadSoft(val) {
            if (val) {
                this.toDownload(val);
            } else {
                this.toDownload(this.choosedSoft);
            }
        }
    }

};
</script>

<style scoped lang="scss" scoped>
.MemberCenter {
    height: calc(100vh - 60px);
    // padding-left: 35px;
    // padding-right: 32px;

    ::v-deep .el-tabs {
        height: calc(100% - 100px);
    }

    :deep .el-tabs__header {
        padding-top: 15px;
        // margin: 0 0 35px
    }

    ::v-deep .el-tabs__content {
        height: calc(100% - 90px);
    }

    ::v-deep .el-tab-pane {
        height: calc(100% - 50px);
    }

    ::v-deep .el-tabs--border-card {
        border: none;
        box-shadow: none;
    }

    // :deep .el-tabs__active-bar {
    //     border-radius: 8px;
    //     background-color: rgb(123, 177, 238);
    // }

    // :deep .el-tabs__item {
    //     font-size: 16px;
    //     color: rgba(128, 128, 128, 0.9);
    // }

    // :deep .el-tabs__item.is-active {
    //     color: rgba(42, 130, 228, 1);
    // }

    // :deep .el-tabs__nav-wrap::after {
    //     display: none;
    // }

    // // table
    ::v-deep .el-table {
        // height: calc(100% - 125px) !important;
        height: 100%;
    }

    :deep .el-table__header .has-gutter tr th {
        background-color: rgba(213, 231, 250, 1);
        border-right: 1px solid #fff;
        color: rgba(56, 56, 56, 1);
    }

    // :deep .el-table th.el-table__cell {
    //     background-color: rgba(245, 245, 245, 1);
    //     border-radius: 20px;
    //     border: none;
    // }

    // :deep .el-table__body tr:hover>td {
    //     background-color: rgba(245, 245, 245, 1) !important;
    // }

    // :deep .el-table tr {
    //     height: 46px;
    // }

    :deep .el-table__body-wrapper {
        height: calc(100% - 100px);
        overflow-y: auto;
    }

    // :deep .el-table__empty-block {
    //     min-height: 50px;
    // }

    // :deep .el-table th.el-table__cell>.cell {
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 22.4px;
    //     color: rgba(128, 128, 128, 0.7);
    // }

    // ::v-deep .el-pagination {
    //     padding-top: 20px;
    // }

}

.downloadList {
    text-align: center;
}
</style>
<style lang="scss" scoped>
.custom-tabs .el-tabs__item {
    position: relative;
    padding: 0 20px;
    /* 调整内边距以适应梯形形状 */
    background-color: #ffffff;
    /* 标签背景色 */
    /* margin-right: 10px; */
    /* 调整标签之间的间距 */

    /* 使用 clip-path 创建梯形形状 */
    clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
}

.custom-tabs>.el-tabs__header .el-tabs__item.is-active {
    color: #fff !important;
}


.custom-tabs .is-active::before {

    content: attr(data-label);
    /* content: ''; */
    /* 使用伪元素显示标签文字 */
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #409EFF;
    /* 梯形背景色 */
    transform-origin: top left;
    color: white;
    /* 文字颜色 */
    line-height: 40px;
    /* 根据具体高度调整 */
    text-align: center;
    z-index: -1;
    /* 确保文字在上层 */
}
</style>